import React from "react";
import { Layout } from "antd";

const Footer = () => (
   <Layout.Footer style={{ margin: 5, padding: 10, textAlign: 'center', background: '#fff' }}>
         KnownSingles © 2020 Created by Nuclei Technologies
   </Layout.Footer>
);

export default Footer;
