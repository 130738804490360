import React from "react";
import SideBar from "../components/side-bar/SideBar";
import { Layout } from "antd";
import Content from "../components/Content";
import Footer from "../components/Footer";
import HeaderContainer from "../containers/headerContainer";

const navList = [
   { id: 1, name: 'Users', icon: 'user', route: '/users' },
   // { id: 2, name: 'Gift Accounts', icon: 'gift', route: '/gift-account' },
   { id: 3, name: 'Push Notifications', icon: 'notification', route: '/push-notification' },
   { id: 4, name: 'Reports', icon: 'warning', route: '/report' },
   { id: 5, name: 'Report content', icon: 'stop', route: '/report-content' },
   { id: 6, name: 'Gift Accounts', icon: 'gift', route: '/gift-account' },
   { id: 7, name: 'Promotions', icon: 'plus', route: '/promotions' },

];

function Main({ children }) {
   return (
      <Layout style={{ minHeight: '100vh' }}>
         <SideBar navList={navList}/>
         <Layout>
            <HeaderContainer/>
            <Content>
               {children}
            </Content>
            <Footer/>
         </Layout>
      </Layout>
   );
}

export default Main;
