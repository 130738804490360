const giftDropDownData = [
   {
      id: '0',
      name: 'Show All'
   },
   {
      id: '1',
      name: 'Activated'
   },
   {
      id: '2',
      name: 'Pending'
   }
]

const reprotContent = [
   {
      id: '0',
      name: 'New'
   },
   {
      id: '1',
      name: 'Accepted'
   },
   {
      id: '2',
      name: 'Rejected'
   }
]

export {
   giftDropDownData,
   reprotContent
}