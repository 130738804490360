import React from 'react';
import { connect } from "react-redux";
import RadioGroup from "../components/RadioGroup";
import { Button, Col, Form, Input, Row, Select, Alert, Spin, Popconfirm, Table } from "antd";
import Column from "antd/lib/table/Column";
import { sendPushNotification, fetchUserCount, fetchSentNotifications } from "../store/pushNotificationReducer";

class PushNotification extends React.Component {
   state = { page: 'sent' };

   componentDidMount = () => {
      document.title = 'Push Notifications';
         this.props.fetchUserCount('all');
         this.props.fetchSentNotifications();
   };


   radioClick = value => {
      this.setState({ page: value },
         () => {
         if (this.state.page === 'sent') {
            this.props.fetchSentNotifications();
         }
      });
   };

   handleSubmit = (e) => {
      e.preventDefault();
      this.props.form.validateFields().then(value => {
         this.props.sendPushNotification(value);
      });

   };

   roleChange = (role) => {
      this.props.fetchUserCount(role);
   };

   renderCreateForm = () => {
      const { TextArea } = Input;
      const { getFieldDecorator } = this.props.form;
      return (
         <div>
            <Row type="flex">
               <Col span={6}>
                  <Form layout="vertical" size="large">
                     <Form.Item hasFeedback label="Campaign Name">
                        {getFieldDecorator('title', {
                           rules: [{ required: true, message: 'Campaign name is required' }],
                        })(
                           <Input/>,
                        )}
                     </Form.Item>
                     <Form.Item hasFeedback label="Your Push Notification">
                        {getFieldDecorator('body', {
                           rules: [{ required: true, message: 'Push Notification is required' }],
                        })(
                           <TextArea rows={5}/>,
                        )}
                     </Form.Item>
                     <Form.Item hasFeedback label="User Type">
                        {getFieldDecorator('role', { initialValue: 'All' })
                        (
                           <Select onChange={this.roleChange}>
                              <Select.Option value="All">All Users</Select.Option>
                              <Select.Option value="Member">Members</Select.Option>
                              <Select.Option value="MatchMaker">MatchMakers</Select.Option>
                           </Select>
                        )}
                     </Form.Item>
                     <Form.Item>
                        <Spin spinning={this.props.countLoading}>
                           <Alert message={`Your Message will be sent to ${this.props.userCount} Users & ${this.props.tokenCount} Devices`} type='success' />
                        </Spin>
                     </Form.Item>
                     <Form.Item>
                        <Popconfirm
                           title="Are you sure?" onConfirm={this.handleSubmit}
                           okText="Yes" cancelText="No">
                        <Button type="primary" htmlType="submit" className="login-form-button">
                           Send Now
                        </Button>
                        </Popconfirm>
                     </Form.Item>
                  </Form>
               </Col>
            </Row>
         </div>
      );
   };

   deliveryText = (data) => {
      return <div>{data.successCount} / {data.successCount + data.failureCount}</div>;
   };

   dateFormate = (data) => {
      return <div>{new Date(data.created).toDateString()}</div>;
   }

   renderSentPushNotifications = () => {
      return (
         <div>
            <Table size='small' dataSource={this.props.notifications} rowKey={record => record.id} loading={this.props.tableLoading}>
               <Column title="Name" dataIndex='name'/>
               <Column title="Notification" dataIndex='notification'/>
               <Column title="Date" render={this.dateFormate} />
               <Column title="Delivery" render={this.deliveryText}/>
            </Table>
         </div>
      );
   };

   render() {
      const radioButtonValues = [
         { value: 'create', displayValue: 'Create' },
         { value: 'sent', displayValue: 'Sent' },
      ];
      return (
         <div>
            <RadioGroup values={radioButtonValues} defaultValue={this.state.page} size='large' onClick={this.radioClick} />
            <br/>
            {this.state.page === 'sent' ? this.renderSentPushNotifications() : this.renderCreateForm()}
         </div>
      );
   }
}

export default connect((state) => {
   return {
      notifications: state.pushNotification.pushNotifications,
      userCount: state.pushNotification.userCount,
      tokenCount: state.pushNotification.tokenCount,
      countLoading: state.pushNotification.countLoading,
      tableLoading: state.pushNotification.tableLoading
   };
},
   {
      sendPushNotification,
      fetchUserCount,
      fetchSentNotifications
   }
)(Form.create()(PushNotification));
