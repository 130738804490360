import React from 'react';
import Row from 'antd/lib/grid/row';
import { Col } from 'antd/lib/grid';
import { Form, message } from 'antd';
import { connect } from 'react-redux';
import Text from 'antd/lib/typography/Text';

import Card from '../components/Card';
import { passwordReset, validateResetUser } from '../store/authReducer';
import PasswordResetForm from '../components/password-reset-form/PasswordResetForm';

class PasswordReset extends React.Component {
	constructor(params) {
		super(params);
		this.state = {
			token: null,
			email: null,
			valid: false
		};
	}

	componentDidMount() {
		document.title = 'Reset Password';
		this.getUserDataFromPath();
	}

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.form.validateFields(async (err, values) => {
			if (!err) {
				if (values.password != values.repassword) {
					message.error('Passwords do not match. Please enter the same password in both fields.');
				} else {
					const success = await this.props.passwordReset(this.state.email, values.password);
					// console.warn('success', success);
				}
			}
		});
	};

	getUserDataFromPath() {
		try {
			let path = this.props.location.pathname;
			path = path.replace('/password-reset/', '');
			path = path.split('/');
			if (path.length >= 2) {
				this.setState({ email: path[0], token: path[1] });
				this.props.validateResetUser(path[0], path[1]);
			} else {
				message.error('Could not verify you. Please use the link in the email');
			}
		} catch (error) {
			// console.warn('error', error);
			message.error('Could not verify you. Please use the link in the email');
		}
	}

	render() {
		const { validateLoading, valdiateResponse, resetLoading, resetResponse } = this.props;
		return (
			<div>
				<Row type="flex" justify="center" align="center" style={{ color: 'rgba(0,0,0,.25)' }}>
					<Col span={6}>
						<Card imgUrl={require('../assets/logo.png')}>
							{validateLoading ? (
								<Text>You are being validated</Text>
							) : valdiateResponse && valdiateResponse.success ? resetResponse && resetResponse.success ? (
								<Text>Your password has been successfully reset. Now you can login to your account via mobile app</Text>
							) : (
								<PasswordResetForm
									form={this.props.form}
									handleSubmit={this.handleSubmit}
									disabled={resetLoading}
									// isLoading={this.props.loginButtonLoading}
								/>
							) : (
								<Text>The Reset Password link is only available for one time usel</Text>
							)}
						</Card>
					</Col>
				</Row>
			</div>
		);
	}
}

const mapDispatchToProps = (state) => {
	return {
		resetLoading: state.auth.passwordResetLoading,
		resetResponse: state.auth.passwordResetResponse,
		validateLoading: state.auth.validateResetLoading,
		valdiateResponse: state.auth.validateResetResponse
	};
};

export default connect(mapDispatchToProps, { passwordReset, validateResetUser })(Form.create()(PasswordReset));
