import { message } from "antd";

export const successMessage = (content) => {
   message.success(content);
};

export const errorMessage = (content) => {
   message.error(content);
};

