import React from 'react';
import { connect } from "react-redux";
import { ban, deactivate, fetchUserContentReports, handleReportContent, checkContentAvaliable } from "../store/reportReducer";
import Column from "antd/lib/table/Column";
import { Button, Table, Modal, Row, Col } from "antd";
import ButtonGroup from "antd/es/button/button-group";
import VideoThumbnail from 'react-video-thumbnail';
import ReactPlayer from 'react-player'
import Dropdown from '../components/Dropdown'
import { reprotContent } from '../assets/constent'

import './reportContent.css';
class ReportContent extends React.Component {
   constructor(props) {
      super(props)
      this.list = reprotContent
   }

   state = {
      isModalVisible: false,
      image: '',
      linkType: 'Image',
      contentAvaliable: true,
      deletedBy: 'User',
      isModalVisibleConfirm: false,
      data: {}
   }

   componentDidMount() {
      document.title = 'Reports';
      this.props.fetchUserContentReports(this.list[0].name);
   }

   showModal = (contents, data, reporterId) => {

      this.props.checkContentAvaliable(reporterId, data).then(res => {
         if (res.data) {
            this.setState({ linkType: data.reportContentType, image: data.reportContent, isModalVisible: true, contentAvaliable: true })
         } else {
            this.setState({ linkType: data.reportContentType, image: data.reportContent, isModalVisible: true, contentAvaliable: false, deletedBy: data.actionTaken == 'Accepted' ? 'admin' : 'user' })
         }
      })

   };

   handleOk = () => {
      this.setState({ isModalVisible: false, isModalVisibleConfirm: false })
      this.props.handleReportContent(this.state.data.reportedUserId, this.state.data)
   };

   handleCancel = () => {
      this.setState({ isModalVisible: false, isModalVisibleConfirm: false, data: {} })
   };

   reportedContent = (content, contentType, reporterId) => {
      return <div onClick={() => this.showModal(content, contentType, reporterId)}><a>{`content link`}</a></div>;
   }

   reportedUser(user) {
      return <div>{`${user.id} - ${user.firstName} ${user.lastName}`}</div>;
   }

   reportedUserDate(reportDate) {
      return <div>{new Date(reportDate).toDateString()}</div>;
   }

   onBan = (id) => {
      this.props.ban(id);
   };

   onDeactivate = (id) => {
      this.props.deactivate(id);
   };

   accept = (reportedUserId, reportId, type, content) => {
      let data = {
         type: 'accept',
         reportId: reportId,
         reportContentType: type,
         reportContent: content,
         reportedUserId: reportedUserId
      }

      this.props.checkContentAvaliable(reportId, data).then(res => {
         if (res.data) {
            this.setState({ linkType: data.reportContentType, image: data.reportContent, isModalVisibleConfirm: true, data: data, contentAvaliable: true })
         } else {
            this.setState({ linkType: data.reportContentType, image: data.reportContent, isModalVisibleConfirm: true, data: data, contentAvaliable: false, deletedBy: data.actionTaken == 'Accepted' ? 'admin' : 'user' })
         }
      })

   }

   reject = (reportedUserId, reportId, type, content) => {
      let data = {
         type: 'reject',
         reportId: reportId,
         reportContentType: type,
         reportContent: content
      }
      this.props.handleReportContent(reportedUserId, data)
   }

   renderActionButtons = (reportedUserId, report) => {
      return (
         <ButtonGroup>
            {report.actionTaken == 'Rejected' || report.actionTaken == 'Accepted' ?
               <Button disabled={true} onClick={() => this.reject(report.reportedUserId, report.id, report.reportContentType, report.reportContent)} type="danger">{report.actionTaken == 'Rejected' ? "Rejected" : "Accepted"}</Button>
               :
               <>
                  <Button onClick={() => this.accept(report.reportedUserId, report.id, report.reportContentType, report.reportContent)} type="primary">Accept</Button>
                  <Button onClick={() => this.reject(report.reportedUserId, report.id, report.reportContentType, report.reportContent)} type="danger">Reject</Button>
               </>
            }
         </ButtonGroup>
      );
   }


   render() {
      return (
         <div>
            <Row type="flex" justify="space-between" align="bottom">
               <Col>
                  <h3>Report Content</h3>
               </Col>
               <Col>
                  <Dropdown listItems={this.list} dataFetch={'Report'} />
               </Col>
            </Row>
            <br />
            <Table size='small' dataSource={this.props.userContentReport} rowKey={record => record.id} loading={this.props.tableLoading}>
               <Column title="Reporter" dataIndex='reporter' />
               <Column title="Reported date" dataIndex='created' key="reportedUserDate.id" render={this.reportedUserDate} />
               <Column title="Reported User" dataIndex='reportedUser' />
               <Column title="Reported content" dataIndex="reportContent,reportContentType" render={this.reportedContent} />
               <Column title="Description" dataIndex='description' />
               <Column title="" dataIndex="reportedUserId,reportedUser" render={this.renderActionButtons} />
            </Table>

            <Modal title="Reported Content" style={{ top: 20 }} visible={this.state.isModalVisible} footer={null} onOk={this.handleOk} onCancel={this.handleCancel}>
               {this.state.contentAvaliable ?
                  this.state.linkType !== 'video' ?

                     <img className="modal-image"
                        src={this.state.image} alt="Reported Content"
                     />
                     :
                     <ReactPlayer
                        url={this.state.image}
                        width={'100%'}
                        light={true}
                        playing={this.state.isModalVisible}
                        controls
                        height={400}
                     /> :
                  <p className="contentText">This content already deleted by {this.state.deletedBy}</p>
               }
            </Modal>

            {/* when admin accept content */}
            <Modal title="Reported Content" style={{ top: 20 }} visible={this.state.isModalVisibleConfirm} onOk={this.handleOk} onCancel={this.handleCancel}>
               {this.state.contentAvaliable ?
                  <div>
                     <p className="contentText">Are you sure you want to remove this content?</p>
                     {this.state.linkType !== 'video' ?

                        <img className="modal-image"
                           src={this.state.image} alt="Reported Content"
                        />
                        :
                        <ReactPlayer
                           url={this.state.image}
                           width={'100%'}
                           light={true}
                           playing={this.state.isModalVisible}
                           controls
                           height={400}
                        />
                     }
                  </div>
                  :
                  <p className="contentText">This content already deleted by {this.state.deletedBy} if you want to accept this report, please press ok</p>
               }
            </Modal>
         </div>
      );
   }
}

export default connect(state => {
   return {
      reports: state.report.reports,
      tableLoading: state.report.tableLoading,
      userContentReport: state.report.userContentReport
   };
}, {
   fetchUserContentReports,
   handleReportContent,
   ban,
   deactivate,
   checkContentAvaliable
})(ReportContent);
