import React from 'react';
import { Icon, Layout, Menu } from 'antd';
import './SideBar.css'
import { NavLink, useLocation } from "react-router-dom";

function SideBar({ navList }) {
   const location = useLocation();
   const selected = navList.findIndex(nav => nav.route.includes(location.pathname));
   console.log(selected);

   return (
      <Layout.Sider style={{ background: '#fff', margin: 5 }}>
         <img className="logo"
              src="https://storage.googleapis.com/staging.knownsingles-3050b.appspot.com/static/avatar.png" alt="logo"/>
         <Menu theme="light" defaultSelectedKeys={[`${selected}`]} mode="inline">
            {navList.map((n, i) =>
               <Menu.Item key={i}>
                  <NavLink to={n.route}><Icon type={n.icon}/>
                     <span>{n.name}</span></NavLink>
               </Menu.Item>
            )}
         </Menu>
      </Layout.Sider>
   );
}

export default SideBar;
