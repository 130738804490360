import React, { useEffect, useState } from 'react'
import { Menu, Dropdown, Button, message, Space, Tooltip } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import { fetchGiftData } from '../store/giftReducers'
import { fetchPromoData } from '../store/promoReducers'
import { fetchUserContentReports } from '../store/reportReducer'

const DropdownComponent = (props) => {
    const { listItems, fetchGiftData, dataFetch, fetchPromoData, fetchUserContentReports } = props
    const [currentItem, setItem] = useState(0)
    const [curentState, setStates] = useState(dataFetch == "Report" ? "New" : 'Show All')

    const setCurrentState = () => {
        switch (currentItem) {
            case listItems[1].id:
                setStates(listItems[1].name)
                break;
            case listItems[2].id:
                setStates(listItems[2].name)
                break;
            default:
                setStates(listItems[0].name)
        }
    }

    const filterFetchData = (key) => {
        setItem(key)
        if (dataFetch == 'Gift') {
            fetchGiftData(key)
        } else if (dataFetch == "Promo") {
            fetchPromoData(key)
        }
        else if (dataFetch == "Report") {
            fetchUserContentReports(key)
        }
    }

    useEffect(() => {
        setCurrentState()
    }, [currentItem])

    const menu = (
        <Menu onClick={(e) => filterFetchData(e.key)}>
            {listItems.map((item) => {
                if (currentItem == currentItem) {
                    if (item.id != currentItem) {
                        return (
                            <Menu.Item key={item.id}>
                                {item.name}
                            </Menu.Item>
                        )
                    }
                } else {
                    return (
                        <Menu.Item key={item.id}>
                            {item.name}
                        </Menu.Item>
                    )
                }
            })}
        </Menu>
    );
    return (
        <Dropdown overlay={menu} >
            <Button>
                {curentState}   <DownOutlined />
            </Button>
        </Dropdown>
    );
};

export default connect(state => {
    return {
    };
}, {
    fetchGiftData,
    fetchPromoData,
    fetchUserContentReports
})(DropdownComponent);
