import * as React from "react";
import { Layout } from "antd";

const Content = ({ children }) => (
   <Layout.Content style={{ margin: 5, padding: 10, background: '#fff' }}>
      <div style={{ margin: 30 }}>
         {children}
      </div>
   </Layout.Content>
);

export default Content;
