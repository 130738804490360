import React, { useState } from 'react';
import { Row, Col, Input, Table, Modal, DatePicker, Button } from "antd";
import Column from "antd/lib/table/Column";
import ButtonGroup from "antd/es/button/button-group";
import { giftDropDownData } from '../assets/constent'
import { connect } from "react-redux";
import { fetchPromoData, updatePromoData, createPromo } from '../store/promoReducers'
import Dropdown from '../components/Dropdown'
import moment from 'moment'


class Promotions extends React.Component {

   constructor(props) {
      super(props)
      this.state = {
         showPromoModal: false,
         showEditModal: false,
         userData: {},
         promoCode: null,
         value: null,
         promoValidCount: null,
         startDate: null,
         expireDate: null,
         checkPromoCode: false,
         checkValidCount: false,
         checkValue: false,
         checkExpireDate: false,
         checkStartDate: false
      }
      this.list = giftDropDownData
      this.newDate = new Date()
      this.newDate.setDate(this.newDate.getDate() + 1);

      this.newDateToday = new Date()
      this.newDateToday.setDate(this.newDateToday.getDate() + 0);

      this.current = new Date()
   }

   componentDidMount() {
      this.props.fetchPromoData(this.list[0].name)
   }

   generateDay(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    }

   handleCancel = () => {
      this.setState({
         showPromoModal: false, showEditModal: false,
         userData: {},
         promoCode: null,
         value: null,
         promoValidCount: null,
         startDate: null,
         expireDate: null,
         checkPromoCode: false,
         checkValidCount: false,
         checkValue: false,
         checkExpireDate: false,
         checkStartDate: false,
         validCheckExpired: false,
         validExpireDayMessage: 'Please add expire date',
         
      })
   };

   updateState = (status, id) => {
      const user = {
         id,
         status
      }
      this.props.updatePromoData(user).then(res => {
         this.props.fetchPromoData(this.list[0].name)
      })
   }

   disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf('day');
   }


   renderActionButtons = user => {
      return (
         <ButtonGroup>
            <Button style={{ backgroundColor: '#bfff00' }} onClick={() => this.setState({ showEditModal: true, userData: user })}>Edit</Button>
            <Button style={{ backgroundColor: '#bfff00' }} onClick={() => this.updateState(user.status ? false : true, user.id)} disabled={user.expireStatus ? true : false}>{user.status ? 'Stop' : user.expireStatus ? 'Expired':'Start'}</Button>
         </ButtonGroup>
      );
   };

   status(status) {
      let currentState
      switch (status) {
         case true:
            currentState = 'Activated'
            break;
         default:
            currentState = 'Pending'
      }

      return (
         <p style={{ marginBottom: 0 }}>{currentState}</p>
      )
   }

   reportedUserDate(reportDate) {
      return <div>{new Date(reportDate).toDateString()}</div>;
   }

   amount(amount) {
      return (
         <p style={{ marginBottom: 0 }}>${amount}</p>
      )
   }

   updateField(field, data) {
      this.setState({ [field]: data })
   }

   updateEditPromotion() {
      this.state.promoCode == '' ? this.setState({ checkPromoCode: true }) : this.setState({ checkPromoCode: false })
      this.state.value == '' ? this.setState({ checkValue: true }) : this.setState({ checkValue: false })
      this.state.promoValidCount == '' ? this.setState({ checkValidCount: true }) : this.setState({ checkValidCount: false })

      if (this.state.startDate == '') {
         this.setState({ checkStartDate: true })
      }

      if (this.state.expireDate == '') {
         this.setState({ checkExpireDate: true, validExpireDayMessage: 'Please add expire date' })
      }

      

      let start = moment(this.state.startDate == null ? this.state.userData.startDate : this.state.startDate)
      let end = moment(this.state.expireDate == null ? this.state.userData.expireDate : this.state.expireDate);


      if ((end.diff(start, 'days') < 0)) {
         this.setState({ checkExpireDate: true, validCheckExpired: true, validExpireDayMessage: 'Please add valid expired day' })
      } else {
         this.setState({ checkExpireDate: false, validCheckExpired: false, validExpireDayMessage: 'Please add expire date' })
         if (!((this.state.promoCode == '') || (this.state.value == '') || (this.state.promoValidCount == '') || this.state.startDate == '' || this.state.expireDate == '') && !(this.state.promoValidCount && this.state.promoValidCount < this.state.userData.promoUsedCount)) {
            const user = {
               id: this.state.userData.id,
               promoCode: this.state.promoCode ? this.state.promoCode : this.state.userData.promoCode,
               value: this.state.value ? this.state.value : this.state.userData.value,
               promoValidCount: this.state.promoValidCount ? this.state.promoValidCount : this.state.userData.promoValidCount,
               startDate: this.state.startDate ? this.state.startDate : this.state.userData.startDate,
               expireDate: this.state.expireDate ? this.state.expireDate : this.state.userData.expireDate,
               expireStatus:(moment(this.current).diff(end, 'days') <= 0) && !(this.state.promoValidCount && this.state.promoValidCount == this.state.userData.promoUsedCount) ? false :true,
               status:(moment(this.current).diff(end, 'days') > 0) ? false : this.state.userData.status
            }
            this.props.updatePromoData(user).then(res => {
                  this.props.fetchPromoData(this.list[0].name)
                  this.handleCancel()
                  this.setState({
                     checkPromoCode: false,
                     checkValidCount: false,
                     checkValue: false,
                     checkExpireDate: false,
                     checkStartDate: false,
                     validCheckExpired: false
                  })
            })
         }
      }
      }

   createPromotion() {
      this.state.promoCode == '' || this.state.promoCode == null ? this.setState({ checkPromoCode: true }) : this.setState({ checkPromoCode: false })
      this.state.value == '' || this.state.value == null ? this.setState({ checkValue: true }) : this.setState({ checkValue: false })
      this.state.promoValidCount == '' || this.state.promoValidCount == null ? this.setState({ checkValidCount: true }) : this.setState({ checkValidCount: false })

      if (this.state.startDate == '') {
         this.setState({ checkStartDate: true })
      }

      if (this.state.expireDate == '') {
         this.setState({ checkExpireDate: true, validExpireDayMessage: 'Please add expire date' })
      }

      let start = moment(this.state.startDate == null ? this.state.userData.startDate : this.state.startDate)
      let end = moment(this.state.expireDate == null ? this.state.userData.expireDate : this.state.expireDate);

      let checkExpiredStatus = (moment(this.current).diff(end, 'days') <= 0)

      if ((end.diff(start, 'days') < 0)) {
         this.setState({ checkExpireDate: true, validCheckExpired: true, validExpireDayMessage: 'Please add valid expired day' })
      } else {
         this.setState({ checkExpireDate: false, validCheckExpired: false, validExpireDayMessage: 'Please add expire date' })
         if (!((this.state.promoCode == '' || this.state.promoCode == null) || (this.state.value == '' || this.state.value == null) || (this.state.promoValidCount == '' || this.state.promoValidCount == null) || this.state.startDate == '' || this.state.expireDate == '')) {
            const user = {
               promoCode: this.state.promoCode,
               value: this.state.value,
               promoValidCount: this.state.promoValidCount,
               startDate: this.state.startDate == null ? this.newDateToday : this.state.startDate,
               expireDate: this.state.expireDate == null ? this.newDateToday : this.state.expireDate,
               promoUsedCount: 0,
               status: true,
               expireStatus: checkExpiredStatus ? false :true
            }
   
            this.props.createPromo(user).then(res => {
               this.props.fetchPromoData(this.list[0].name)
               this.handleCancel()
               this.setState({
                  checkPromoCode: false,
                  checkValidCount: false,
                  checkValue: false,
                  checkExpireDate: false,
                  checkStartDate: false,
                  validExpireDayMessage: 'Please add expire date'
               })
   
            })
   
         }
      }

   }

   render() {
      return (
         <div>
            <Row type="flex" justify="space-between" align="bottom">
               <Col>
                  <Row type="flex">
                     <h3>Promotions</h3>
                     <Button onClick={() => this.setState({ showPromoModal: true, userData: {} })} style={{ marginLeft: '10px' }} type="danger" icon="plus">
                        Add New
                     </Button>
                  </Row>
               </Col>

               <Col>
                  <Dropdown listItems={this.list} dataFetch={'Promo'} />
               </Col>
            </Row>
            <br />
            <Table size='small' dataSource={this.props.promoData} rowKey={record => record.id} loading={this.props.tableLoading}>
               <Column title="Promo Code" dataIndex='promoCode' />
               <Column title="Value" dataIndex='value' render={this.amount} />
               <Column title="Promo Valid Count" dataIndex='promoValidCount' />
               <Column title="Promo Used Count" dataIndex='promoUsedCount' />
               <Column title="Start" dataIndex='startDate' render={this.reportedUserDate} />
               <Column title="Expiry" dataIndex='expireDate' render={this.reportedUserDate} />
               <Column title="Status" dataIndex='status' render={this.status} />
               <Column render={this.renderActionButtons} />
            </Table>

            <Modal title={this.state.showEditModal ? "Update Promotion" : "Create Promotion"} style={{ top: 20 }} visible={this.state.showEditModal || this.state.showPromoModal} footer={null} onCancel={this.handleCancel}>
               <Row gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>
                  <Col className="gutter-row" span={24} style={styles.contanierMarginSamll} >
                     <div>
                        <h6>Promo Code</h6>
                        <Input disabled={this.state.showEditModal ? true : false} name="promoCode" onBlur={() => this.state.promoCode == '' ? this.setState({ checkPromoCode: true }) : this.setState({ checkPromoCode: false })}
                           key={`promoCode:${this.state.showPromoModal ? '' : this.state.userData.promoCode || this.state.userData.promoCode}`} defaultValue={this.state.showEditModal ? this.state.userData.promoCode : null} onChange={(e) => this.updateField('promoCode', e.target.value)} />
                        <p style={styles.validationFont}>{!this.state.promoCode && this.state.checkPromoCode ? 'Please fill the promo code' : null}</p>
                     </div>
                  </Col>
                  <Col className="gutter-row" span={24} style={styles.contanierMarginSamll}>
                     <div>
                        <h6>Value</h6>
                        <Input name="value" onBlur={() => this.state.value == '' ? this.setState({ checkValue: true }) : this.setState({ checkValue: false })}
                           key={`value:${this.state.showPromoModal ? '' : this.state.userData.value || this.state.userData.value}`} placeholder="$" defaultValue={this.state.showEditModal ? this.state.userData.value : null} onChange={(e) => this.updateField('value', e.target.value)} />
                        <p style={styles.validationFont}>{!this.state.value && this.state.checkValue ? 'Please add value' : null}</p>
                     </div>
                  </Col>
                  <Col className="gutter-row" span={24} style={styles.contanierMarginSamll}
                  >
                     <h6>Count</h6>
                     <Input name="promoValidCount" onBlur={() => this.state.promoValidCount == '' ? this.setState({ checkValidCount: true }) : this.setState({ checkValidCount: false })}
                        key={`promoValidCount:${this.state.showPromoModal ? '' : this.state.userData.promoValidCount || this.state.userData.promoValidCount}`} placeholder="Count" defaultValue={this.state.showEditModal ? this.state.userData.promoValidCount : null} onChange={(e) => this.updateField('promoValidCount', e.target.value)} />
                     <p style={styles.validationFont}>{!this.state.promoValidCount && this.state.checkValidCount ? 'Please add count' : this.state.promoValidCount && this.state.promoValidCount < this.state.userData.promoUsedCount  ? `Please add valid count greter than ${this.state.userData.promoUsedCount} or equl to ${this.state.userData.promoUsedCount} ` : null}</p>
                  </Col>
                  <Col className="gutter-row" span={24} style={styles.contanierMargin}
                  >
                     <h6>Start Date</h6>
                     <DatePicker style={styles.contanierWidth} name="startDate" onBlur={() => this.state.startDate == '' ? this.setState({ checkStartDate: true }) : this.setState({ checkStartDate: false })}
                        key={`startDate:${this.state.showPromoModal ? moment(this.newDate) : this.state.userData.startDate || this.state.userData.startDate}`}
                        defaultValue={moment(this.state.showEditModal ? this.state.userData.startDate : this.newDateToday, 'YYYY-MM-DD')} onChange={(date, dateString) => this.updateField('startDate', dateString)} />
                     <p style={styles.validationFont}>{!this.state.startDate && this.state.checkStartDate ? 'Please add start date' : null}</p>
                  </Col>
                  <Col className="gutter-row" span={24} style={styles.contanierMargin}
                  >
                     <h6>Expiry Date</h6>
                     <DatePicker style={styles.contanierWidth} name="expireDate" onBlur={() => this.state.expireDate == '' ? this.setState({ checkExpireDate: true }) : this.setState({ checkExpireDate: false })}
                        key={`expireDate:${this.state.showPromoModal ? moment(this.newDate, 'YYYY-MM-DD') : this.state.userData.expireDate }`} defaultValue={moment(this.state.showEditModal ? this.state.userData.expireDate : this.newDateToday, 'YYYY-MM-DD')} onChange={(date, dateString) => this.updateField('expireDate', dateString)} />
                     {this.state.validCheckExpired ?
                        <p style={styles.validationFont}>{this.state.validExpireDayMessage}</p>
                        : <p style={styles.validationFont}>{!this.state.expireDate && this.state.checkExpireDate ? this.state.validExpireDayMessage : null}</p>
                     }

                  </Col>
               </Row>
               <Button style={{ marginTop: '20px', backgroundColor: '#bfff00' }} onClick={() => this.state.showEditModal ? this.updateEditPromotion() : this.createPromotion()}>{this.state.showEditModal ? "Update Promo Code" : "Create Promo Code"}</Button>
            </Modal>
         </div>
      );
   }


};

const styles = {
   validationFont: {
      color: 'red',
      fontSize: 10,
      marginBottom: 5,
      marginTop: 2
   },
   contanierMargin: {
      marginTop: 10
   },
   contanierMarginSamll: {
      marginTop: 5
   },
   contanierWidth: {
      width: '100%'
   }

}

export default connect(state => {
   return {
      promoData: state.promo.promoData,
      tableLoading: state.promo.tableLoading,
   };
}, {
   fetchPromoData,
   updatePromoData,
   createPromo
})(Promotions);