import { axios } from "../api/knownsingles";
import { errorMessage, successMessage } from "./message";

const initState = {
    giftData: [],
    tableLoading: false,
};

export const FETCH_GIFT_ATTEMPT = 'report/fetch_gift_attempt';
export const FETCH_GIFT_SUCCESS = 'report/fetch_gift_success';
export const FETCH_GIFT_FAIL = 'report/fetch_gift_fail';


const reducer = (state = initState, action) => {
    switch (action.type) {
        case FETCH_GIFT_ATTEMPT: {
            return { ...state, tableLoading: true };
        }
        case FETCH_GIFT_SUCCESS: {
            return { ...state, giftData: action.payload, tableLoading: false }
        }
        case FETCH_GIFT_FAIL: {
            return { ...state, tableLoading: false }
        }
        default:
            return state;
    }
};

export const fetchGiftData = (status) => async dispatch => {
    dispatch({ type: FETCH_GIFT_ATTEMPT });
    try {
        const { data } = await axios.get(`gifts/${status}`);
        dispatch({ type: FETCH_GIFT_SUCCESS, payload: data });
    } catch (e) {
        dispatch({ type: FETCH_GIFT_FAIL });
        errorMessage("Something went wrong, Please try again later");
    }
};



export default reducer;
