import axios from "axios";

const API_URL = process.env.API_URL || 'https://stage-api.knownsingles.com';

const authAxios = axios.create({
   baseURL: API_URL,
});

const commonAxios = axios.create({
   baseURL: API_URL
});

commonAxios.interceptors.request.use(req => {
   const token = localStorage.getItem('token');
   if (token) {
      req.headers["Authorization"] = `Bearer ${token}`;
   }
   return req;
});

export { commonAxios as axios, authAxios };
