import React from 'react';
import { Button, Form, Icon, Input } from 'antd';
import './PasswordResetForm.css';
import Text from 'antd/lib/typography/Text';

const PasswordResetForm = ({ form, handleSubmit, reset, isLoading, disabled }) => {
	const { getFieldDecorator } = form;

	return (
		<Form onSubmit={handleSubmit}>
			<Form.Item>
				<Text>Reset your account password</Text>
			</Form.Item>
			<Form.Item>
				{getFieldDecorator('password', {
					rules: [ { required: true, message: 'Please enter your Password!' } ]
				})(
					<Input
						prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
						type="password"
						placeholder="Password"
						disabled={disabled}
					/>
				)}
			</Form.Item>
			<Form.Item>
				{getFieldDecorator('repassword', {
					rules: [ { required: true, message: 'Please enter your Password again!' } ]
				})(
					<Input
						prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
						type="password"
						placeholder="Retype Password"
						disabled={disabled}
					/>
				)}
			</Form.Item>
			<Form.Item>
				<Button type="primary" htmlType="submit" className="login-form-button" loading={isLoading} disabled={disabled}>
					Reset Password
				</Button>
			</Form.Item>
		</Form>
	);
};

export default PasswordResetForm;
