import React, { Component } from 'react';
import { Row, Col, Input, Table, Popover, Avatar, Button } from "antd";

import RadioGroup from "../components/RadioGroup";
import { connect } from "react-redux";
import { changeUserType, loadUsers, reset, search, ban, deactivate } from "../store/userReducer";
import Column from "antd/lib/table/Column";
import ButtonGroup from "antd/es/button/button-group";


class Users extends Component {

   componentDidMount() {
      document.title = 'Users';
      this.loadUsers(this.props.userType);
   }

   loadUsers = (userType) => {
      this.props.loadUsers(userType);
   };

   radioClick = value => {
      this.props.changeUserType(value);
      this.props.loadUsers(value);
   };

   onSearch = (value) => {
      value = value.trim();
      this.props.search(value);
   };

   onReset = (user) => {
      this.props.reset(user);
   };

   onBan = (id) => {
      this.props.ban(id)
   };

   onDeactivate = (id) => {
      this.props.deactivate(id)
   };

   renderAvatar = avatarUrl => {
      return (
         <Popover content={<img src={avatarUrl} alt="" width="auto" height="100%"/>}>
            <Avatar src={avatarUrl}/>
         </Popover>
      );
   };

   renderName = user => {
      return <div>{user.firstName} {user.lastName}</div>;
   };

   renderActionButtons = user => {
      return (
         <ButtonGroup>
            <Button onClick={() => this.onReset(user)} type="default">Reset</Button>
            <Button onClick={() => this.onBan(user.id)} type="danger">{user.banned ? 'Unban' : 'Ban'}</Button>
            <Button onClick={() => this.onDeactivate(user.id)} type="primary">{user.activated ? 'Deactivate' : 'Activate'}</Button>
         </ButtonGroup>
      );
   };

   cityState(user) {
      if (user.city && user.state) {
         return <div>{user.city} / {user.state}</div>;
      }
      return <div>{user.city}{user.state}</div>;
   }

   render() {
      const radioButtonValues = [
         { value: 'Member', displayValue: 'Singles' },
         { value: 'MatchMaker', displayValue: 'MatchMakers' },
      ];
      return (
         <div>
            <Row type="flex" justify="space-between" align="bottom">
               <Col>
                  <RadioGroup values={radioButtonValues} defaultValue={this.props.userType} size='large' onClick={this.radioClick}/>
               </Col>
               <Col>
                  <Input.Search placeholder="search"
                                style={{ width: 300 }}
                                loading={this.props.searchLoading}
                                onSearch={this.onSearch}/>
               </Col>
            </Row>
            <br/>

            <Table size='small' dataSource={this.props.users} rowKey={record => record.id} loading={this.props.tableLoading}>
               <Column dataIndex="avatarUrl" key='avatarUrl' render={this.renderAvatar}/>
               <Column title="ID" dataIndex="id" key="id"/>
               <Column title="Name" render={this.renderName}/>
               <Column title="Email" dataIndex='email'/>
               <Column title="City / State" render={this.cityState}/>
               <Column title="Country" dataIndex='country'/>
               <Column title="Action" render={this.renderActionButtons}/>
            </Table>
         </div>
      );
   }
}

const mapStateToProps = state => (
   {
      users: state.user.filteredUsers,
      userType: state.user.userType,
      tableLoading: state.user.tableLoading,
      searchLoading: state.user.searchLoading
   }
);

export default connect(
   mapStateToProps,
   { changeUserType, loadUsers, search, reset, ban, deactivate }
)(Users);
