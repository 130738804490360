import _ from 'lodash';
import { axios } from "../api/knownsingles";
import { errorMessage } from "./message";

export const CHANGE_USER_TYPE = 'user/change_user_type';
export const FETCH_USERS_ATTEMPT = 'user/fetch_user_attempt';
export const FETCH_USERS_SUCCESS = 'user/fetch_user_success';
export const FETCH_USERS_FAIL = 'user/fetch_user_fail';

export const RESET_USERS_ATTEMPT = 'user/reset_user_attempt';
export const RESET_USERS_SUCCESS = 'user/reset_user_success';
export const RESET_USERS_FAIL = 'user/reset_user_fail';

export const BAN_USERS_ATTEMPT = 'user/ban_user_attempt';
export const BAN_USERS_SUCCESS = 'user/ban_user_success';
export const BAN_USERS_FAIL = 'user/ban_user_fail';

export const DEACTIVATE_USERS_ATTEMPT = 'user/deactivate_user_attempt';
export const DEACTIVATE_USERS_SUCCESS = 'user/deactivate_user_success';
export const DEACTIVATE_USERS_FAIL = 'user/deactivate_user_fail';

export const SEARCH_ATTEMPT = 'user/search_attempt';
export const SEARCH_COMPLETE = 'user/search_complete';


const initialState = {
   users: [],
   filteredUsers: [],
   userType: 'Member',
   tableLoading: false,
   searchLoading: false
};

const userReducer = (state = initialState, action) => {
   switch (action.type) {
      case CHANGE_USER_TYPE: {
         return { ...state, userType: action.payload }
      }
      case FETCH_USERS_ATTEMPT: {
         return { ...state, tableLoading: true }
      }
      case FETCH_USERS_SUCCESS: {
         return { ...state, users: action.payload, filteredUsers: action.payload, tableLoading: false }
      }
      case FETCH_USERS_FAIL: {
         return { ...state, tableLoading: false }
      }
      case SEARCH_ATTEMPT: {
         return { ...state, searchLoading: true, tableLoading: true }
      }
      case SEARCH_COMPLETE: {
         return { ...state, filteredUsers: action.payload, searchLoading: false, tableLoading: false }
      }
      case BAN_USERS_ATTEMPT: {
         return { ...state, tableLoading: true }
      }
      case BAN_USERS_SUCCESS: {
         return {
            ...state,
            users: state.users.map(u => u.id === action.payload.id ? action.payload : u),
            filteredUsers: state.filteredUsers.map(u => u.id === action.payload.id ? action.payload : u),
            tableLoading: false
         }
      }
      case BAN_USERS_FAIL: {
         return { ...state, tableLoading: false }
      }
      case DEACTIVATE_USERS_ATTEMPT: {
         return { ...state, tableLoading: true }
      }
      case DEACTIVATE_USERS_SUCCESS: {
         return {
            ...state,
            users: state.users.map(u => u.id === action.payload.id ? action.payload : u),
            filteredUsers: state.filteredUsers.map(u => u.id === action.payload.id ? action.payload : u),
            tableLoading: false
         }
      }
      case DEACTIVATE_USERS_FAIL: {
         return { ...state, tableLoading: false }
      }
      case RESET_USERS_ATTEMPT: {
         return { ...state, tableLoading: true }
      }
      case RESET_USERS_SUCCESS: {
         return { ...state, tableLoading: false }
      }
      case RESET_USERS_FAIL: {
         return { ...state, tableLoading: false }
      }
      default:
         return state;

   }
};

export const changeUserType = (userType) => ({ type: CHANGE_USER_TYPE, payload: userType });

export const loadUsers = (role) => async dispatch => {
   dispatch({ type: FETCH_USERS_ATTEMPT });
   try {
      const { data } = await axios.get(`users/role/${role}`);
      dispatch({ type: FETCH_USERS_SUCCESS, payload: data });
   } catch (e) {
      dispatch({ type: FETCH_USERS_FAIL });
      errorMessage("Something went wrong, Please try again later");
   }
};

export const ban = (id) => async dispatch => {
   dispatch({ type: BAN_USERS_ATTEMPT });

   try {
      const { data } = await axios.put(`users/switch-ban/${id}`);
      dispatch({ type: BAN_USERS_SUCCESS, payload: data });
   } catch (e) {
      dispatch({ type: BAN_USERS_FAIL });
      errorMessage("Something went wrong, Please try again later");
   }

};

export const reset = (user) => async dispatch => {
   dispatch({ type: RESET_USERS_ATTEMPT });

   try {
      const { data } = await axios.put(`users/password-reset-request`, { email: user.email });
      dispatch({ type: RESET_USERS_SUCCESS, payload: data });
   } catch (e) {
      dispatch({ type: RESET_USERS_FAIL });
      errorMessage("Something went wrong, Please try again later");
   }
};

export const deactivate = (id) => async dispatch => {
   dispatch({ type: DEACTIVATE_USERS_ATTEMPT });

   try {
      const { data } = await axios.put(`users/switch-activate/${id}`);
      dispatch({ type: DEACTIVATE_USERS_SUCCESS, payload: data });
   } catch (e) {
      dispatch({ type: DEACTIVATE_USERS_FAIL });
      errorMessage("Something went wrong, Please try again later");
   }
};

export const search = (text) => async (dispatch, getState) => {
   dispatch({ type: SEARCH_ATTEMPT });
   let { user: { users } } = getState();
   users = _.filter(users, u => JSON.stringify(u).toLowerCase().includes(text.toLowerCase()));
   // setTimeout(() => dispatch({ type: SEARCH_COMPLETE, payload: users }), 2000);
   dispatch({ type: SEARCH_COMPLETE, payload: users });

};

export default userReducer;
