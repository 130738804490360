import React from 'react';
import { Card as AntCard } from 'antd';

const Card = ({ imgUrl, children }) => {
   return (
      <AntCard
         cover={
            <img alt="example" src={imgUrl} />
         }
      >
         {children}
      </AntCard>
   );
};

export default Card;
