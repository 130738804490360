import React, { Component } from 'react';
import Header from "../components/Header";
import { connect } from "react-redux";
import { logout } from "../store/authReducer";

class HeaderContainer extends Component {
   render() {
      return (
         <div>
            <Header logoutHandler={this.props.logout}/>
         </div>
      );
   }
}

export default connect(null, { logout })(HeaderContainer);
