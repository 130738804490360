import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import userReducer from "./userReducer";
import authReducer from "./authReducer";
import pushNotificationReducer from "./pushNotificationReducer";
import reportReducer from "./reportReducer";
import giftReducer from "./giftReducers"
import promoReducer from "./promoReducers"

const rootReducer = combineReducers({
   user: userReducer,
   auth: authReducer,
   pushNotification: pushNotificationReducer,
   report: reportReducer,
   gift:giftReducer,
   promo:promoReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;
