import { authAxios } from '../api/knownsingles';
import { successMessage } from './message';
import { message } from 'antd';
import jwt from 'jsonwebtoken';

export const LOGIN_ATTEMPT = 'LOGIN_ATTEMPT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const PASSWORD_RESET_ATTEMPT = 'PASSWORD_RESET_ATTEMPT';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL';

export const VALIDATE_RESET_ATTEMPT = 'VALIDATE_RESET_ATTEMPT';
export const VALIDATE_RESET_SUCCESS = 'VALIDATE_RESET_SUCCESS';
export const VALIDATE_RESET_FAIL = 'VALIDATE_RESET_FAIL';

const initialState = {
	loginButtonLoading: false,
	loggedIn: null,
	loggedInUser: null,
	passwordResetLoading: false,
	passwordResetResponse: null,
	validateResetLoading: false,
	validateResetResponse: null
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN_ATTEMPT: {
			return { ...state, loginButtonLoading: true };
		}
		case LOGIN_SUCCESS: {
			return { ...state, loggedIn: true, loggedInUser: action.payload, loginButtonLoading: false };
		}
		case LOGIN_FAIL: {
			return { ...state, loginButtonLoading: false };
		}
		case LOGOUT_SUCCESS: {
			return { ...state, loggedInUser: null, loggedIn: false };
		}
		case PASSWORD_RESET_ATTEMPT: {
			return { ...state, passwordResetLoading: true };
		}
		case PASSWORD_RESET_SUCCESS: {
			return { ...state, passwordResetLoading: false, passwordResetResponse: action.payload };
		}
		case PASSWORD_RESET_FAIL: {
			return { ...state, passwordResetLoading: false, passwordResetResponse: action.payload };
		}
		case VALIDATE_RESET_ATTEMPT: {
			return { ...state, validateResetLoading: true };
		}
		case VALIDATE_RESET_SUCCESS: {
			return { ...state, validateResetLoading: false, validateResetResponse: action.payload };
		}
		case VALIDATE_RESET_FAIL: {
			return { ...state, validateResetLoading: false, validateResetResponse: action.payload };
		}
		default: {
			return state;
		}
	}
};

export const login = (email, password, remember) => async (dispatch) => {
	dispatch({ type: LOGIN_ATTEMPT });
	try {
		const { data } = await authAxios.post('auth/login', { email, password });
		successMessage('Successfully logged in');
		if (remember) {
			setToken(data.token);
		}
		const user = jwt.decode(data.token);
		dispatch({ type: LOGIN_SUCCESS, payload: user });
		return true;
	} catch ({ response: { data } }) {
		dispatch({ type: LOGIN_FAIL });
		message.error(data.message);
		return false;
	}
};

export const logout = () => {
	removeToken();
	return { type: LOGOUT_SUCCESS };
};

const setToken = (token) => {
	localStorage.setItem('token', token);
};

const removeToken = () => {
	localStorage.removeItem('token');
};

export const getAuthStateFromToken = () => {
	const token = localStorage.getItem('token');
	if (!token) return false;

	const decoded = jwt.decode(token);
	if (!decoded) return false;

	const expDate = new Date(decoded.exp * 1000);
	return expDate > new Date();
};

export const passwordReset = (email, password) => async (dispatch) => {
	dispatch({ type: PASSWORD_RESET_ATTEMPT });
	try {
		const { data } = await authAxios.post('auth/password-reset', { email, password });
		successMessage('Password reset successful');
		dispatch({ type: PASSWORD_RESET_SUCCESS, payload: data });
		return true;
	} catch ({ response: { data } }) {
		dispatch({ type: PASSWORD_RESET_FAIL, payload: data });
		message.error(data.message);
		return false;
	}
};

export const validateResetUser = (email, token) => async (dispatch) => {
	dispatch({ type: VALIDATE_RESET_ATTEMPT });
	try {
		const { data } = await authAxios.get('auth/check-reset-token/' + email + '/' + token);
		successMessage('Validate successful');
		dispatch({ type: VALIDATE_RESET_SUCCESS, payload: data });
		return true;
	} catch ({ response: { data } }) {
		dispatch({ type: VALIDATE_RESET_FAIL, payload: data });
		message.error(data.message);
		return false;
	}
};

export default authReducer;
