import { axios } from "../api/knownsingles";
import { errorMessage, successMessage } from "./message";

export const FETCH_USER_COUNT_ATTEMPT = 'push_notifications/fetch_user_count_attempt';
export const FETCH_USER_COUNT_SUCCESS = 'push_notifications/fetch_user_count_success';
export const FETCH_USER_COUNT_FAIL = 'push_notifications/fetch_user_count_fail';
export const FETCH_NOTIFICATION_ATTEMPT = 'push_notifications/fetch_notification_attempt';
export const FETCH_NOTIFICATION_SUCCESS = 'push_notifications/fetch_notification_success';
export const FETCH_NOTIFICATION_FAIL = 'push_notifications/fetch_notification_fail';

const initialState = {
   pushNotifications: [],
   userCount: 0,
   tokenCount: 0,
   countLoading: false,
   tableLoading: false
};

const pushNotificationReducer = (state = initialState, action) => {
   switch (action.type) {
      case FETCH_USER_COUNT_ATTEMPT: {
         return { ...state, countLoading: true };
      }
      case FETCH_USER_COUNT_SUCCESS: {
         return { ...state, userCount: action.payload.userCount, tokenCount: action.payload.tokenCount, countLoading: false };
      }
      case FETCH_USER_COUNT_FAIL: {
         return { ...state, countLoading: false };
      }
      case FETCH_NOTIFICATION_ATTEMPT: {
         return { ...state, tableLoading: true };
      }
      case FETCH_NOTIFICATION_SUCCESS: {
         return { ...state, pushNotifications: action.payload, tableLoading: false };
      }
      case FETCH_NOTIFICATION_FAIL: {
         return { ...state, tableLoading: false };
      }
      default:
         return state;
   }
};

export const fetchUserCount = (role) => async dispatch => {
   dispatch({ type: FETCH_USER_COUNT_ATTEMPT });
   try {
      const { data } = await axios.get(`push-notifications/count/${role}`)
      dispatch({ type: FETCH_USER_COUNT_SUCCESS, payload: data });
   } catch (e) {
      dispatch({ type: FETCH_USER_COUNT_FAIL });
      errorMessage("Fetch user count failed, Please try again later");
   }
};

export const fetchSentNotifications = () => async dispatch => {
   dispatch({ type: FETCH_NOTIFICATION_ATTEMPT });
   try {
      const { data } = await axios.get('push-notifications');
      dispatch({type: FETCH_NOTIFICATION_SUCCESS, payload: data});
   } catch (e) {
      dispatch({ type: FETCH_NOTIFICATION_FAIL });
   }
};

export const sendPushNotification = (body) => async dispatch => {
   try {
      const { data } = await axios.post(`push-notifications/send-to-all`, body);
      console.log(data);
      successMessage("Push Notification sent successfully");
   } catch (e) {
      console.log(e);
      errorMessage("Something went wrong, Please try again later");
   }
};

export default pushNotificationReducer;
