import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import Main from "../pages/main";
import Login from "../pages/login";
import Users from "../pages/users";
import PushNotification from "../pages/pushNotification";
import Report from "../pages/report";
import GiftAccount from "../pages/giftAccount";
import Promotions from "../pages/promotions";
import PasswordReset from '../pages/passwordReset';
import ReportContent from '../pages/reportContent';

const PublicRoute = (props) => <Route {...props} />;

const PrivateRoute = ({ path, component: Component }) => {
   return <Route
      path={path}
      render={props => {
         return (
            <Main>
               <Component {...props}/>
            </Main>
         );
      }
      }
   />;
};

const Routes = () => {
   return (
      <Router>
         <Switch>
            <PublicRoute path='/login' component={Login}/>
            <Redirect exact from='/' to='/login'/>
            <PrivateRoute path='/users' component={Users}/>
            <PrivateRoute path='/push-notification' component={PushNotification}/>
            <PrivateRoute path='/report' component={Report}/>
            <PrivateRoute path='/report-content' component={ReportContent}/>
            <PrivateRoute path='/gift-account' component={GiftAccount}/>
            <PrivateRoute path='/promotions' component={Promotions}/>
				<Route path="/password-reset" component={PasswordReset} />
			</Switch>
      </Router>
   );
};

export default Routes;
