import React from 'react';
import { Row, Col, Input, Table, Popover, Avatar, Button } from "antd";
import Column from "antd/lib/table/Column";
import Dropdown from '../components/Dropdown'
import { connect } from "react-redux";
import { fetchGiftData } from '../store/giftReducers'
import {giftDropDownData} from '../assets/constent'
class GiftAccount extends React.Component {
   constructor(props) {
      super(props)
      this.list = giftDropDownData
   }

   componentDidMount() {
      this.props.fetchGiftData(this.list[0].name)
   }

   status(data) {
      let currentState
      if(data.expireStatus){
         currentState = 'Expired'
      }else{
         switch(data.subscription){
            case true:
               currentState = 'Activated'
            break;
            default:
               currentState = 'Pending'
         }
      }
      

      return (
         <p>{currentState}</p>
      )
   }

   loadData(status){
      this.props.fetchGiftData(status)
   }

   render() {
      return (
         <div>
            <Row type="flex" justify="space-between" align="bottom">
               <Col>
                  <h3>Gift Accounts</h3>
               </Col>
               <Col>
                  <Dropdown listItems={this.list} dataFetch={'Gift'} />
               </Col>
            </Row>
            <br />
            <Table size='small' dataSource={this.props.gifts} rowKey={record => record.id} loading={this.props.tableLoading}>
               <Column title="Gifted By" dataIndex='byName' />
               <Column title="Gifted To - Name" dataIndex='toName' />
               <Column title="Gifted To - Email" dataIndex='toEmail' />
               <Column title="Active Status" dataIndex='' render={this.status} />
            </Table>
         </div>
      );
   }

};

export default connect(state => {
   return {
      gifts: state.gift.giftData,
      tableLoading: state.report.tableLoading,
   };
}, {
   fetchGiftData
})(GiftAccount);