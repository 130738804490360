import { axios } from "../api/knownsingles";
import { errorMessage, successMessage } from "./message";

const initState = {
    promoData: [],
    tableLoading: false,
};

export const FETCH_PROMO_ATTEMPT = 'report/fetch_promo_attempt';
export const FETCH_PROMO_SUCCESS = 'report/fetch_promo_success';
export const FETCH_PROMO_FAIL = 'report/fetch_promo_fail';

export const UPDATE_PROMO_ATTEMPT = 'report/update_promo_attempt';
export const UPDATE_PROMO_SUCCESS = 'report/update_promo_success';
export const UPDATE_PROMO_FAIL = 'report/update_promo_fail';

export const CREATE_PROMO_ATTEMPT = 'report/create_promo_attempt';
export const CREATE_PROMO_SUCCESS = 'report/create_promo_success';
export const CREATE_PROMO_FAIL = 'report/create_promo_fail';


const reducer = (state = initState, action) => {
    switch (action.type) {
        case FETCH_PROMO_ATTEMPT: {
            return { ...state, tableLoading: true };
        }
        case FETCH_PROMO_SUCCESS: {
            return { ...state, promoData: action.payload, tableLoading: false }
        }
        case FETCH_PROMO_FAIL: {
            return { ...state, tableLoading: false }
        }
        case UPDATE_PROMO_ATTEMPT: {
            return { ...state, tableLoading: true };
        }
        case UPDATE_PROMO_SUCCESS: {
            return { ...state, tableLoading: false }
        }
        case UPDATE_PROMO_FAIL: {
            return { ...state, tableLoading: false }
        }
        default:
            return state;
    }
};

export const fetchPromoData = (status) => async dispatch => {
    dispatch({ type: FETCH_PROMO_ATTEMPT });
    try {
        const { data } = await axios.get(`promo/${status}`);
        dispatch({ type: FETCH_PROMO_SUCCESS, payload: data });
    } catch (e) {
        dispatch({ type: FETCH_PROMO_FAIL });
        errorMessage("Something went wrong, Please try again later");
    }
};


export const createPromo = (status) => async dispatch => {
    dispatch({ type: CREATE_PROMO_ATTEMPT });
    try {
        const res = await axios.post('promo/createPromo',status);
        if(res.data == 'Promo code already existing'){
            errorMessage(res.data);
            return false
        }else{
            dispatch({ type: CREATE_PROMO_SUCCESS});
            successMessage(res.data.message)
            return true
        }
    } catch (e) {
        dispatch({ type: CREATE_PROMO_FAIL });
        errorMessage("Something went wrong, Please try again later");
    }
};


export const updatePromoData = (status) => async dispatch => {
    dispatch({ type: UPDATE_PROMO_ATTEMPT });
    try {
        const res = await axios.put('promo/updatePromo',status);
        dispatch({ type: UPDATE_PROMO_SUCCESS});
        successMessage(res.data.message)
        return 
    } catch (e) {
        dispatch({ type: UPDATE_PROMO_FAIL });
        errorMessage("Something went wrong, Please try again later");
    }
};


export default reducer;
