import React from 'react';
import { Button, Checkbox, Form, Icon, Input } from "antd";
import './LoginForm.css';

const LoginForm = ({ form, handleSubmit, reset, isLoading }) => {

   const { getFieldDecorator } = form;

   return (
      <Form onSubmit={handleSubmit}>
         <Form.Item hasFeedback>
            {getFieldDecorator('email', {
               rules: [{ required: true, message: 'Please enter your email!' }],
            })(
               <Input
                  prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }}/>}
                  placeholder="Email"
               />,
            )}
         </Form.Item>
         <Form.Item>
            {getFieldDecorator('password', {
               rules: [{ required: true, message: 'Please input your Password!' }],
            })(
               <Input
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }}/>}
                  type="password"
                  placeholder="Password"
               />,
            )}
         </Form.Item>
         <Form.Item>
            {getFieldDecorator('remember', {
               valuePropName: 'checked',
               initialValue: true,
            })(<Checkbox>Remember me</Checkbox>)}
            <Button type="primary" htmlType="submit" className="login-form-button" loading={isLoading}>
               Log in
            </Button>
            <Button type="default" className="login-form-button" onClick={reset}>
               Clear
            </Button>
         </Form.Item>
      </Form>
   );
};

export default LoginForm;
