import React from 'react';
import { Radio } from "antd";

const RadioGroup = ({ values, size, onClick, defaultValue }) => {
   return (
      <div>
         <Radio.Group size={size} defaultValue={defaultValue} buttonStyle="solid">
            {
               values.map((value, i) => {
                     return <Radio.Button
                        key={i}
                        value={value.value}
                        onClick={_ => onClick(value.value)}>
                        {value.displayValue}
                     </Radio.Button>;
                  }
               )
            }
         </Radio.Group>
      </div>
   );
};

export default RadioGroup;
