import React from 'react';
import { connect } from "react-redux";
import { ban, deactivate, fetchReports } from "../store/reportReducer";
import Column from "antd/lib/table/Column";
import { Button, Table } from "antd";
import ButtonGroup from "antd/es/button/button-group";

class Report extends React.Component {

   componentDidMount() {
      document.title = 'Reports';
      this.props.fetchReports();
   }

   reporter(user) {
      return <div>{`${user.id} - ${user.firstName} ${user.lastName}`}</div>;
   }

   reportedUser(user) {
      return <div>{`${user.id} - ${user.firstName} ${user.lastName}`}</div>;
   }

   reportedUserDate(reportDate) {
     return <div>{new Date(reportDate).toDateString()}</div>;
   }

   onBan = (id) => {
      this.props.ban(id);
   };

   onDeactivate = (id) => {
      this.props.deactivate(id);
   };

   renderActionButtons = user => {
      return (
         <ButtonGroup>
            <Button onClick={() => this.onBan(user.id)} type="danger">{user.banned ? 'Unban' : 'Ban'}</Button>
            <Button onClick={() => this.onDeactivate(user.id)} type="primary">{user.activated ? 'Deactivate' : 'Activate'}</Button>
         </ButtonGroup>
      );
   }

   render() {
      return (
         <div>
            <Table size='small' dataSource={this.props.reports} rowKey={record => record.id} loading={this.props.tableLoading}>
               <Column title="Description" dataIndex='description'/>
               <Column title="Reporter" dataIndex='reporter' render={this.reporter}/>
               <Column title="Reported User" dataIndex='reportedUser' key="reportedUser.id" render={this.reportedUser}/>
               <Column title="Report Date" dataIndex='created' key="reportedUserDate.id" render={this.reportedUserDate}/>
               <Column title="Action" dataIndex="reportedUser" render={this.renderActionButtons}/>
            </Table>
         </div>
      );
   }
}

export default connect(state => {
   return {
      reports: state.report.reports,
      tableLoading: state.report.tableLoading
   };
}, {
   fetchReports,
   ban,
   deactivate
})(Report);
