import React from "react";
import { Button, Layout } from "antd";
import { useHistory } from "react-router-dom";


const Header = ({ logoutHandler }) => {

   const history = useHistory();

   const onClick = () => {
      logoutHandler();
      history.push('/login');
   };

   return (
      <Layout.Header style={{ background: '#fff', margin: 5 }}>
         <b>KnownSingles Admin Panel</b> - Operational Backend for Mobile Apps
         <span style={{ float: 'right' }}>
               <Button onClick={onClick} type="danger">Logout</Button>
         </span>
      </Layout.Header>
   );
};

export default Header;
