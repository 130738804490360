import { axios } from "../api/knownsingles";
import { errorMessage, successMessage } from "./message";

const initState = {
   reports: [],
   tableLoading: false,
   userContentReport: []
};

export const FETCH_REPORTS_ATTEMPT = 'report/fetch_reports_attempt';
export const FETCH_REPORTS_SUCCESS = 'report/fetch_reports_success';
export const FETCH_REPORTS_FAIL = 'report/fetch_reports_fail';
export const FETCH_USER_CONTENT_REPORTS_ATTEMPT = 'FETCH_USER_CONTENT_REPORTS_ATTEMPT'
export const FETCH_USER_CONTENT_REPORTS_SUCCESS = 'FETCH_USER_CONTENT_REPORTS_SUCCESS'
export const FETCH_USER_CONTENT_REPORTS_FAIL = 'FETCH_USER_CONTENT_REPORTS_FAIL'

export const BAN_ATTEMPT = 'report/ban_attempt';
export const BAN_SUCCESS = 'report/ban_success';
export const BAN_FAIL = 'report/ban_fail';

export const DEACTIVATE_ATTEMPT = 'report/deactivate_attempt';
export const DEACTIVATE_SUCCESS = 'report/deactivate_success';
export const DEACTIVATE_FAIL = 'report/deactivate_fail';

const reducer = (state = initState, action) => {
   switch (action.type) {
      case FETCH_REPORTS_ATTEMPT: {
         return { ...state, tableLoading: true };
      }
      case FETCH_REPORTS_SUCCESS: {
         return { ...state, reports: action.payload, tableLoading: false }
      }
      case FETCH_REPORTS_FAIL: {
         return { ...state, tableLoading: false }
      }
      case BAN_ATTEMPT: {
         return { ...state, tableLoading: true }
      }
      case BAN_SUCCESS: {
         return {
            ...state,
            tableLoading: false,
            reports: state.reports.map(report => {
               if (report.reportedUser.id === action.payload.id)
                  report.reportedUser = action.payload;
               return report;
            })
         };
      }
      case BAN_FAIL: {
         return { ...state, tableLoading: false }
      }
      case DEACTIVATE_ATTEMPT: {
         return { ...state, tableLoading: true }
      }
      case DEACTIVATE_SUCCESS: {
         return {
            ...state,
            tableLoading: false,
            reports: state.reports.map(report => {
               if (report.reportedUser.id === action.payload.id)
                  report.reportedUser = action.payload;
               return report;
            })
         };
      }
      case DEACTIVATE_FAIL: {
         return { ...state, tableLoading: false }
      }
      case FETCH_USER_CONTENT_REPORTS_SUCCESS: {
         return { ...state, userContentReport: action.payload }
      }
      default:
         return state;
   }
};

export const fetchReports = () => async dispatch => {
   dispatch({ type: FETCH_REPORTS_ATTEMPT });
   try {
      const { data } = await axios.get(`relationships/report`);
      dispatch({ type: FETCH_REPORTS_SUCCESS, payload: data });
   } catch (e) {
      dispatch({ type: FETCH_REPORTS_FAIL });
      errorMessage("Something went wrong, Please try again later");
   }
};

export const fetchUserContentReports = (id) => async dispatch => {
   try {
      const data = await axios.get(`relationships/reportDetails/${id}`);
      dispatch({ type: FETCH_USER_CONTENT_REPORTS_SUCCESS, payload: data.data });
   } catch (e) {
      dispatch({ type: FETCH_USER_CONTENT_REPORTS_FAIL });
      errorMessage("Something went wrong, Please try again later");
   }
};


export const handleReportContent = (id, data) => async dispatch => {
   try {
      const res = await axios.post(`relationships/handleReportDetails/${id}`, data)
      if (res) {
         dispatch(fetchUserContentReports())
      }
      if (data.type == 'accept') {
         successMessage('Accepted reported content successfully')
      } else {
         successMessage('Content was rejected successfully')
      }
   } catch (e) {
      errorMessage("Something went wrong, Please try again later");
   }
};

export const ban = (id) => async dispatch => {
   dispatch({ type: BAN_ATTEMPT });

   try {
      const { data } = await axios.put(`users/switch-ban/${id}`);
      dispatch({ type: BAN_SUCCESS, payload: data });
   } catch (e) {
      dispatch({ type: BAN_FAIL });
      errorMessage("Something went wrong, Please try again later");
   }

};

export const deactivate = (id) => async dispatch => {
   dispatch({ type: DEACTIVATE_ATTEMPT });

   try {
      const { data } = await axios.put(`users/switch-activate/${id}`);
      dispatch({ type: DEACTIVATE_SUCCESS, payload: data });
   } catch (e) {
      dispatch({ type: DEACTIVATE_FAIL });
      errorMessage("Something went wrong, Please try again later");
   }
};

export const checkContentAvaliable = (id, data) => async dispatch => {
   try {
      const res = await axios.post(`relationships/checkContentAvaliable/${id}`, data)
      return res
   } catch (e) {
      errorMessage("Something went wrong, Please try again later");
   }
};


export default reducer;
