import React from "react";
import Card from "../components/Card";
import { Form } from "antd";
import LoginForm from "../components/login-form/LoginForm";
import Row from "antd/lib/grid/row";
import { Col } from "antd/lib/grid";
import { connect } from "react-redux";
import { getAuthStateFromToken, login } from "../store/authReducer";

class Login extends React.Component {

   componentDidMount() {
      document.title = 'Login';
      if (getAuthStateFromToken()) {
         this.props.history.push('/users');
      }
   }

   handleSubmit = e => {
      e.preventDefault();
      this.props.form.validateFields(async (err, values) => {
         if (!err) {
            const success = await this.props.login(values.email, values.password, values.remember);
            if (success) {
               this.props.history.push('/users');
            }
         }
      });
   };

   reset = () => {
      this.props.form.resetFields(['email', 'password', 'remember'])
   };

   render() {

      return (
         <Row type="flex" justify="center">
            <Col span={5}>
               <Card imgUrl={require('../assets/logo.png')}>
                  <LoginForm
                     form={this.props.form}
                     handleSubmit={this.handleSubmit}
                     reset={this.reset}
                     isLoading={this.props.loginButtonLoading}
                  />
               </Card>
            </Col>
         </Row>
      );
   }
}

const mapDispatchToProps = (state) => {
   return { loginButtonLoading: state.auth.loginButtonLoading, user: state.auth.loggedInUser };
};

export default connect(mapDispatchToProps, { login })(Form.create()(Login));
